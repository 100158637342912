import React from 'react';
import '../App.css';
import AuthPanel from './AuthPanel';
import SubscriptionForm from './Subscription';
import BulletPoints from './BulletPoints';
const HeroSection = () => {
  
  return (
    <div className="hero-section">
      <div className="hero-content">
        <div className="hero-left">
          <h1>Speed Up Your Recruitment Process with <span style={{ color: 'purple' }}>ChronoFlow</span></h1>
          <p>A Cost Effective AI driven tool for Independent Recruiters and staffing agencies to reduce recruitment time by 50%</p>
          <SubscriptionForm />
          <AuthPanel />

        </div>
        <div className="hero-divider"></div> {/* Divider element */}
        <div className="hero-right">
        <BulletPoints />
        </div>
    </div>
    </div>
  );
};

export default HeroSection;