import React, { FC } from "react";
import { Card, Button, Col } from "antd";
import { IPlan } from "./constants";
import "../../App.css"; // Assuming you have some additional styles here

interface Props {
  plan: IPlan;
  onClick: (e: IPlan) => void;
}
const PlanCard: FC<Props> = ({ plan, onClick }) => {
  return (
    <Col xs={24} sm={12} md={8}>
      <Card className="pricing-card" title={plan.title}>
        <div className="card-body-container">
          <div className="card-description">
            <p className="plan-price">
              {plan.preFix || ""}
              {plan.salePrice || plan.price}
              {plan.postFix && (
                <span style={{ fontSize: 12 }}>{plan.postFix}</span>
              )}
            </p>
            {/* {plan.salePrice && (
              <div className="sale-price-container">
                <p className="sale-price-badge">
                  <span className="actual-price">
                    Actual Price: {plan.preFix || ""}
                    {plan.price}
                  </span>
                  <span className="percentage">
                    (
                    {((plan.salePrice || 0) / parseInt(plan.price.toString())) *
                      100}{" "}
                    % OFF)
                  </span>
                </p>
              </div>
            )} */}
            <ul className="plan-features">
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
          {plan.btn && (
            <Button
              type="primary"
              className="plan-button"
              onClick={() => onClick(plan)}
            >
              {plan.btn}
            </Button>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default PlanCard;
